import '../../common/simplero_image_preloader/index'
import '../../common/image_transitioner/index'
import { preload } from '../../common/image_transitioner/preload'

// Preload the images when they're (nearly) in view.
document.addEventListener('DOMContentLoaded', () => {
  let observer = new IntersectionObserver(
    function (entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          preload(entry.target.querySelector('.image-transitioner'))
          observer.unobserve(entry.target)
        }
      })
    },
    { rootMargin: '800px' }
  )

  document
    .querySelectorAll('.simplero-new-feature-with-images__container')
    .forEach((el) => observer.observe(el))
})
